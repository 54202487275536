.cardblog{
height: auto;
width: 100%;    
border-radius: 25px;
margin-bottom: 20px;
background: #ffffff;
box-shadow:  20px 20px 60px #d9d9d9,
             -20px -20px 60px #ffffff;
}


.imgblog{
    object-fit: cover;
    width: 100%;
    height: 300px;
}
.tituloblog{
    margin-block-start: 0em;
    margin-bottom: 10px;
    font-size: 34px;
    line-height: 1.1;
    font-weight: 600;
    color: #0082cc;
}
.tituloblog:hover{
    color: #0066a1;
}
.divblogcontentpostcard{
    margin-top: 5px;
    padding: 10px;

    display: flex;
    flex-direction: column;
}
.divdescriptionblog{
    margin-right: 15px;
    margin-left: 5 px;
}
.divblogcreator{
    display: flex;
    flex-direction: row;
    margin-left: 0px;
    margin-bottom: 5px;
    margin-top: 10px;
}
.divblogcreate{
    display: flex;
    flex-direction: row;
    margin-left: auto;
    align-items: center;
    justify-items: center;
    align-content: center;
    justify-content: center;
}

.imgcreator{
    border-radius: 50%;
    object-fit: cover;
    width: 30px;
    height: 30px;
    border: 3px solid #b3b3b3;
}

.txtcreator{
  
        margin-block-start: 0em;
        margin-bottom: 13px;
        margin-top: 5px;
        margin-left: 5px;
        font-size: 20px;
        line-height: 1.1;
        font-weight: 300;
        color: #b3b3b3;
    
}

.datecreator{
    margin-block-start: 0em;
        margin-bottom: 13px;
        margin-left: 5px;
        font-size: 20px;
        line-height: 1.1;
        font-weight: 300;
        color: #b3b3b3;
}

.linktitle{
    text-decoration: none;
}

.paradescriptionblog{
    margin-block-start: 0em;
    width: auto;
    line-height: 18px;
     margin: 10px 0;
     text-align: justify;
}