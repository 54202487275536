.cardrecientes{
    width: 100%;
    height: auto;
    border-radius: 28px;
    margin-bottom: 15px;
background: linear-gradient(145deg, #ffffff, #e6e6e6);
box-shadow:  14px 14px 28px #c9c9c9,
             -14px -14px 28px #ffffff;
}

.cardrecientes:hover{
    border-radius: 28px;
    background: #ffffff;
    box-shadow:  14px 14px 28px #c9c9c9,
                 -14px -14px 28px #ffffff;
}
.imgrecientes{
    object-fit: cover;
    border-radius: 50%;
    height: 65px;
    width: 65px;
}
.divcardrecientes{
    display: flex;
    flex-direction: row;
}

.divimgrecientes{
    margin: 15px;
}
.titulorecientes{
    margin-block-start: 0em;
  
    font-size: 18px;
    line-height: 1.1;
    font-weight: 500;
    color: #0082cc;
}
.divtitulorecientes{
    margin-top: 10px;
    display:flex;
    flex-direction: column;
}
.fecharecientes{
    margin-block-start: 0em;
    font-size: 15px;
    line-height: 1.1;
    font-weight: 500;
    color: #aaaaaa;
}
.divsubtitulorecientes{
    display: flex;
    flex-direction: row;
}

.categoriarecientes{
    margin-block-start: 0em;
    margin-left: auto;
    margin-right: 5px;
    font-size: 15px;
    line-height: 1.1;
    font-weight: 300;
    color: #0082cc;
}