#caracteristicas {
    background-color: #0082CC;
    padding: 50px 0;
  
}
.text-center {
    text-align: center;
}
.separadorgradiente{
    height: 5px;
    width: 100px;
    border: none;
    background: linear-gradient(to right, #006caa 0%, #00B0FF 100%);
}
.caract-container{
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.caract-titulo-h1{
    margin-bottom: 10px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: #f7f8fa;
}
.caract-titulo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 50px;
}
.caracter{
    width: 180px;
    margin-left: 40px;
    margin-right: 40px;
}
.caract-icono{
    font-size: 38px;
    margin-bottom: 0px;
	transition: all 0.5s;
	color: #fff;
    width: 150px;
	height: 100px;
	padding: 30px 0;
    border-radius: 25%;
background: #0082cc;
box-shadow:  20px 20px 60px #006fad,
             -20px -20px 60px #0096eb;
}
.row{
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
}
.titulo-caracteristica{
    color: #f7f8fa;
    margin-bottom: 10px;
    font-size: 30px;

}
.caract-descripcion{
    color: #fff;
	font-size: 18px;
	font-weight: 300;
	line-height: 25px;
	margin: 0 auto;
	
}
@media screen and (max-width:912px){
    .row {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;
    }
    .caract-descripcion {
        color: #fff;
        font-size: 18px;
        font-weight: 300;
        line-height: 25px;
        margin: 0 auto;
        margin-bottom: 30px;
    }
}
@media screen and (max-width: 412px){
    .row{
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;
    }
    .titulo-caracteristica{
        margin-block-start: 0;
        color: #f7f8fa;
        margin-bottom: 10px;
        font-size: 30px;
        
    
    }
    .caract-descripcion {
        color: #fff;
        font-size: 18px;
        font-weight: 300;
        line-height: 25px;
        margin: 0 auto;
        margin-bottom: 30px;
    }
}