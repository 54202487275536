.divfootermain{
    height: auto;
    width: 100%;
    background: linear-gradient(#e8eaeb, #ffffff);
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
    justify-content:space-between;
}

.divfooterblock{
    margin: 20px;
}
.divtituloblock{
    width: 100%;
    margin-top: 10px;
}
.tituloblock{
    margin-block-start: 0em;
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 1.1;
    font-weight: 400;
    color: #0082cc;
}
.txtcontactfooter{
    margin-block-start: 0em;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 1.1;
    font-weight: 400;
    color: #0082cc;
    text-decoration: none;
}
.txtcontactfooter:hover{
    color: #01598b;
    cursor: pointer;
    text-decoration: none;
}
.contact-item{
    margin-bottom: 5px;
}