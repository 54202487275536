.cardcategoria{
    width: 100%;
    height: 40px;
    margin-bottom: 20px;
    border-radius: 77px;
    background: linear-gradient(145deg, #ffffff, #e6e6e6);
    box-shadow:  14px 14px 28px #c9c9c9,
                 -14px -14px 28px #ffffff;
                 display: flex;
                 align-items: center;
                 justify-content: center;
   
}
.cardcategoria:hover{
    border-radius: 77px;
   
background: #ffffff;
box-shadow:  14px 14px 28px #c9c9c9,
             -14px -14px 28px #ffffff;

}
.txtcategoria{
    margin-block-start: 0em;
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 1.1;
    font-weight: 400;
    color: #0082cc;
    justify-content: center;
}

.txtlinkcategoria{
    text-decoration: none;
    margin-top: 5px;
}
