.divmadreblogpost{
    display: flex;
    flex-direction: row;
}
.divblogcontent{
 
    width: 100%;
    
    margin: 10px;
}
.divblogcontent2{
    width: 40%;
 
    flex-grow: 1;
    margin-right: 25px;
    padding: 5px;
}
.divmainblogpost{
    margin-bottom: 0px;
}
.divrecientesblogpost{
    width: 100%;
 
    
 
}
.divmarcasblogpost{
    width: 100%;
    padding: 10px;
}

.divimagenblogpost{
    margin: 20px;
}

.imgblogpost{
    object-fit: cover;
    width: 100%;
    height: 400px;
}
.divcontentpost{
    margin: 20px;
}
.paradescriptionblogpost{
    margin-block-start: 0em;
    width: auto;
    line-height: 18px;
     margin: 10px 0;
     text-align: justify;
}

@media screen and (max-width: 700px){
    .divmadreblogpost{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 10px;
    }
    .divblogcontent{
 
        width: 100%;
        
    
    }

}