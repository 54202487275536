.titulo_blog_principal{
    margin-block-start: 0em;
    margin-bottom: 10px;
    font-size: 34px;
    line-height: 1.1;
    font-weight: 600;
    color: #0082cc;
}

.titulo_blog_secundario{
    margin-block-start: 0em;
    margin-bottom: 10px;
    font-size: 26px;
    line-height: 1.1;
    font-weight: 400;
    color: #0082cc;

}
.divmadreblog{
    display: flex;
    flex-direction: row;
    
    padding: 15px;
}

.divhijo2blog{
    margin-left: 20px;
   
    width: 40%;
}
.divhijo1blog{
    width: 100%;
}
.divtituloblog{
    margin-left: 30px;
    margin-top: 20px;
}

.divtitulocategorias{
    margin-left: 10px;
    margin-top: 50px;
    margin-bottom: 20px;
}

.divblogsection{
    margin-left: 30px;
    margin-top: 20px;
}

.divcategorias{
    width: 100%;
}
.carouseldiv{
    width: 100%;
    
}
.cargandocontent{
    margin-block-start: 0em;
    margin-bottom: 10px;
    font-size: 25px;
    line-height: 1.1;
    font-weight: 300;
    color: #0082cc;
}

@media screen and (max-width: 700px){
    .divmadreblog{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 15px;
    }

    .divhijo2blog{
        
        margin-left: 0px;
        width: 100%;
    }
    .divhijo1blog{
        width: 100%;
    }
    .divtituloblog{
        margin-left: 0px;
        margin-top: 20px;
    }
    .divblogsection{
        margin-left: 0px;
        margin-top: 10px;
    }

}