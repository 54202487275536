.header-section{
    display: table;
	width: 100%;
	height:560px;
	padding: 0;
	background: url('./intro.jpg') center center no-repeat;
	background-color: #e5e5e5;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	-o-background-size: cover;

}
.header-overlay{
    width: 100%;
    height: 100%;
    background-color: rgba(222, 223, 231, 0.4);
   
}
.heading {
    margin-bottom: 10px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: #f7f8fa;
  }

.header-container{
    padding-top: 100px;
    display: flex;
    justify-content: center;
    align-content: center;
}

.home__hero-text-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.home__hero-subtitle{
    width: 700px;
    color: #fff;
	font-size: 22px;
	font-weight: 300;
	line-height: 30px;
	margin: 0 auto;
    margin-bottom: 15px;
    text-align: center;
}
.logoheader {
    width: 720px;
    padding: 10px;
}
@media screen and (max-width: 450px){
    .header-section{
        width: 100%;
        height: 420px;
        background: url('./intro.jpg');
        background-color: #e5e5e5;
        background-size: 100% 100%;
    
    }
    .header-overlay{
        width: 100%;
        height: 100%;
        background-color: rgba(222, 223, 231, 0.4);
       
    }
    .logoheader {
        width: 340px;
        margin-top: 40px;
        padding: 10px;
    }
    .home__hero-subtitle{
        width: 300px;
        margin-top: 20px;
        color: #fff;
        font-size: 22px;
        font-weight: 300;
        line-height: 30px;
        margin: 0 auto;
        margin-bottom: 15px;
        text-align: center;
    }
    .heading {
        margin-bottom: 10px;
        font-size: 40px;
        line-height: 1.1;
        font-weight: 600;
        color: #f7f8fa;
        text-align: center;
      }
    .linkbtn{
        margin-bottom: 20px;
    }
}


