#about {
    padding: 50px 0;
    padding: 50px 20px;
 }
 .container-about{
     padding-right: 0px;
     padding-left: 0px;
     margin-right: auto;
     margin-left: auto;
 }
 .row {
     display: flex;
   }
   .divdescription{
       display: flex;
       flex-direction: column;
       align-items: flex-start;
   }
   .titudescription{
     margin-block-start: 0;
     margin-bottom: 10px;
     font-size: 36px;
     line-height: 1.1;
     font-weight: 600;
     color: #0082cc;
   }
   .subdescription{
     margin-block-start: 0em;
     margin-bottom: 10px;
     font-size: 30px;
     line-height: 1.1;
     font-weight: 600;
     color: #0082cc;
   }
   .col {
 
     align-self: flex-start;
     flex: 50%;
     padding-left: 10px;
     padding-right: 10px;
   }
 
   .imgabout {
     padding: 10px 6px;
     width: 100%;
 }
   .rowlista{
       display: flex;
 
   }
 
   .paradescription{
     line-height: 24px;
     margin: 10px 0;
     text-align: justify;
     
   }
   .collista{
       flex: 50%;
   }
 
 .ulabout {
     list-style-type: none;
     margin-left: 10px;
 }
 
 .liabout{
     margin-bottom: 12px;
     margin-left: -10px;
     display: flex;
     align-items: center;
 }
 
 .liabout:before {
     color: transparent;
     font-size: 1px;
     content: " ";
     margin-left: -1.3em;
     margin-right: 15px;
     padding: 10px;
      background: linear-gradient(to right, #006caa 0%, #00B0FF 100%);
     mask-image: url("./check.svg");
     mask-size: cover;
 }
 .separadorgradienteabout{
     height: 5px;
     margin-left: 1px;
     width: 100px;
     border: none;
      background: linear-gradient(to right, #006caa 0%, #00B0FF 100%);
 
 }
 
 @media screen and (max-width: 412px){
     .imgabout {
         padding: 10px 0px;
         width: 100%;
     }
     .titudescription {
         margin-block-start: 0;
         margin-bottom: 10px;
         font-size: 30px;
         line-height: 1.1;
         font-weight: 600;
         color: #0082cc;
     }
     .paradescription {
         line-height: 24px;
         margin: 10px 0;
         text-align: justify;
         width: 300px;
     }
     .subdescription {
         margin-block-start: 0em;
         margin-bottom: 10px;
         font-size: 20px;
         line-height: 1.1;
         font-weight: 600;
         color: #0082cc;
     }
     .ulabout {
     list-style-type: none;
     margin-left: 1px;
     padding-inline-start: 10px;
     }
 }
 
 
 